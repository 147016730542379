<template>
  <div class="home">
    
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    
  },
  created () {
    this.$store.commit('updateLoader', true)
  },
}
</script>
